import { Controller } from "@hotwired/stimulus"
import { enable, disable } from "../utils"

// Connects to data-controller="undo-redo"
export default class extends Controller {
  static targets = ["undoButton", "redoButton"]

  undoButtonTarget: HTMLButtonElement
  redoButtonTarget: HTMLButtonElement

  hasUndoButtonTarget: boolean
  hasRedoButtonTarget: boolean

  undoStack = []
  redoStack = []

  // Semaphore to prevent infinite loop due to tomselect triggering change event on setValue
  propagateEvents = true

  handleAddUndoActionsFunction: (event) => void

  connect(): void {
    this.handleAddUndoActionsFunction = this.handleAddUndoActions.bind(this)
    window.addEventListener("undo-redo:add-undo-actions", this.handleAddUndoActionsFunction)
  }

  disconnect(): void {
    window.removeEventListener("undo-redo:add-undo-actions", this.onAddUndoActionFunction)
  }

  undoAction(): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const actions = this.undoStack.pop()

      if (actions) {
        actions.forEach((action) => this.updateInput(action.id, action.previous_value))
        this.redoStack.push(actions)
        this.refreshButtons()
      }

      this.propagateEvents = true
    }
  }

  redoAction(): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const actions = this.redoStack.pop()

      if (actions) {
        actions.forEach((action) => this.updateInput(action.id, action.current_value))
        this.undoStack.push(actions)
        this.refreshButtons()
      }

      this.propagateEvents = true
    }
  }

  addUndoAction(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      this.addUndoActions([
        {
          id: event.target.id,
          previous_value: event.target.dataset.value || "",
          current_value: event.target.value,
        },
      ])

      this.propagateEvents = true
    }
  }

  private handleAddUndoActions(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      this.addUndoActions(event.detail.actions)

      this.propagateEvents = true
    }
  }

  private updateInput(inputId, value): void {
    const input = document.getElementById(inputId)

    if (input) {
      input.dataset.value = value

      if (input.tomselect) {
        input.tomselect.setValue(value)
      } else {
        input.value = value
      }
    }
  }

  private addUndoActions(actions): void {
    actions.forEach((action) => {
      const input = document.getElementById(action.id)

      if (input) {
        input.dataset.value = action.current_value
      }
    })

    this.undoStack.push(actions)
    this.redoStack = []
    this.refreshButtons()
  }

  private refreshButtons(): void {
    if (this.hasUndoButtonTarget) {
      this.undoStack.length > 0 ? enable(this.undoButtonTarget) : disable(this.undoButtonTarget)
    }

    if (this.hasRedoButtonTarget) {
      this.redoStack.length > 0 ? enable(this.redoButtonTarget) : disable(this.redoButtonTarget)
    }
  }
}
